.container-view {
    max-width: 1200px;

    margin: 0px auto;
    margin-top: 25px !important; 
    margin-bottom: 10px !important;

    padding-top: 10px;

    box-shadow: 0px 0px 10px 0px rgb(223, 223, 223);
    border-radius: 4px;
    background-color: white;
}

.container-view .row.container-header {
    padding: 0px;
    margin: 0px 14px 0px 14px;
}

.container-content {
    margin: 30px;
}

.header-content {
    background-color: #434242;
    color: white;
}

.profile-buttons {
    margin: 0px auto;
    align-content: center;
}

.profile-list {
    text-align: left;
}

.timeline-description {
    color: rgba(0,0,0,0.68);
}